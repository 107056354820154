<template>
  <main>
    <CForm>
      <CCard>
        <CCardHeader class="d-flex justify-content-between align-items-center">
          <h5 class="font-weight-bold mb-0">{{ $t('Navigation.Organization/ListManage') }}</h5>
          <div v-if="$store.state.user.permission.Permission" class="card-header-actions d-flex">
            <CButton v-if="$store.state.user.permission.Permission.Organization && $store.state.user.permission.Permission.Organization.Manager.includes('W')" color="success" variant="outline" shape="pill" @click="AddManagerModal()">
              <CIcon size="sm" class="mr-1" name="cil-plus"/> {{ $t('Global.Add') }}
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
            id="ManagerList"
            :items="List"
            :fields="Field"
            :loading="Loading"
            :noItemsView="noItemsView"
            responsive
            hover
          >
            <template #Checkbox="{item}">
              <td class="text-center">
                <label class="mb-0">
                  <input type="checkbox" :id="'Checkbox-User-' + item.uid" :value="item.uid" v-model="ChooseUsers">
                </label>
              </td>
            </template>
            <template #identity="{item}">
              <td>
                {{ $t('Organization/Manager.IdentityOptions.' + item.Level) }}
              </td>
            </template>
            <template #disabled="{item}">
              <td>
                <CBadge :color="(item.disabled === false ? 'success' : 'danger')">
                  {{ $t('Organization.' + (item.disabled === false ? 'Enable' : 'Disable')) }}
                </CBadge>
              </td>
            </template>
            <template #emailVerified="{item}">
              <td>
                <CBadge :color="(item.emailVerified === true ? 'success' : 'danger')">
                  {{ $t('Organization.' + (item.emailVerified === true ? 'Verified' : 'Unverified')) }}
                </CBadge>
              </td>
            </template>
            <template #creationTime="{item}">
              <td>
                {{ $dayjs(item.metadata.creationTime).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
            <template #lastSignInTime="{item}">
              <td>
                {{ item.metadata.lastSignInTime ? $dayjs(item.metadata.lastSignInTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
              </td>
            </template>
            <template #Action="{item}">
              <td v-if="$store.state.user.permission.Permission">
                <CButton v-if="$store.state.user.permission.Permission.Organization && $store.state.user.permission.Permission.Organization.Manager.includes('W')" color="info" size="sm" class="mr-1" @click="GetManager(item.uid)">
                  <CIcon name="cil-pencil" class="c-icon-custom-size"/>
                </CButton>
                <CButton v-if="$store.state.user.permission.Permission.Organization && $store.state.user.permission.Permission.Organization.Manager.includes('D')" color="danger" size="sm" class="mr-1" @click="RemoveManager(item.uid)">
                  <CIcon name="cil-x" class="c-icon-custom-size"/>
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardBody class="d-flex justify-content-between">
          <div>
            <h5 class="text-info">
              管理員配額
            </h5>
            <div>
              目前管理員可新增的配額為 <b>{{ $store.state.project.ManagerMaxItems || 6 }}</b> 個，若您需要更多配額或是其他臨時需求，可聯繫專案業務或是<b>申請提高配額</b>。
            </div>
            <CButton color="success" class="px-4 mt-3" @click="$router.push('/feedback')" size="sm">
              申請提高配額
            </CButton>
          </div>
        </CCardBody>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
      </CCard>
    </CForm>
    <CModal id="CreateManagerModel" class="ActionModel" :show.sync="CreateManagerModel" size="lg" :centered="true" :closeOnBackdrop="!Submit">
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Organization/Manager.' + (Action === 'Update' ? 'UpdateManager' : 'CreateManager')) }}</h5>
          <CButtonClose @click="CreateManagerModel = false"/>
        </header>
      </template>
      <CTabs :active-tab="0" :key="User.uid" fade addTabsWrapperClasses="tab-info">
        <CTab :title="$t('Organization/Manager.Basic')">
          <CInput v-if="$store.state.user.permission.Level === 0" horizontal name="project" v-model="SetProject" :label="$t('Personal.Profile/ProjectCode')" class="d-none" autocomplete="off"/>
          <CInput horizontal v-model="User.displayName" name="displayName" :label="$t('Organization.Name')" autocomplete="off"/>
          <CInput horizontal v-model="User.email" name="email" :label="$t('Organization.Email')" :disabled="(Action === 'Update')" autocomplete="off" />
          <CInput horizontal v-model="UserData.Mobile" name="phoneNumber" :label="$t('Organization.Mobile')" autocomplete="off" @blur="CheckPhone()" />
          <CInput horizontal :type="(PasswordVisible ? 'text' : 'password')" number="password" v-model="User.password" autocomplete="new-password" :label="$t('Organization.Password')">
            <template #append>
              <CButton color="light" @click="PasswordVisible = !PasswordVisible">
                <CIcon class="my-0" :name="(PasswordVisible ? 'cil-lock-unlocked' : 'cil-lock-locked')" size="sm"/>
              </CButton>
            </template>
          </CInput>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
              {{ $t('Organization.EnabledStatus') }}
            </CCol>
            <CCol sm="9">
              <CSwitch color="success" :checked.sync="User.enabled"/>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('Organization/Manager.Navigation')">
          <div v-for="(NavData, Key) in CurrentPermission.Navigation" :key="Key">
            <template v-if="NavData && Object.keys(NavData || {}).length > 0">
              <h5>{{ $t('Organization/Manager.NavigationGroup.' + Key) }}</h5>
              <hr>
              <div class="form-inline mb-5">
                <label v-for="Item in NavData" :key="Item" :for="Key + Item" class="mr-2">
                  <input type="checkbox" :id="Key + Item" :name="Key" :value="Item" v-model="Permissions.Navigation[Key]"><span class="ml-2">{{ $t('Navigation.' + Key + '/' + Item) }}</span>
                </label>
              </div>
            </template>
          </div>
        </CTab>
        <CTab :title="$t('Organization/Manager.Features')">
          <div v-for="(FeatureData, Key) in CurrentPermission.Features" :key="Key">
            <template v-if="FeatureData && Object.keys(FeatureData || {}).length > 0">
              <h5>{{ $t('Organization/Manager.NavigationGroup.' + Key) }}</h5>
              <hr>
              <template v-if="typeof FeatureData === 'object'">
                <div v-if="Array.isArray(FeatureData)" class="form-inline mb-5">
                  <label v-for="Item in FeatureData" :key="Item" :for="Key + Item" class="mr-2">
                    <input @input="() => (!Permissions.Features[Key] && ($set(Permissions.Features, Key, [])))" type="checkbox" :id="Key + Item" :value="Item" v-model="Permissions.Features[Key]">
                    <span class="ml-2">{{ $t('Organization/Manager.FeaturesGroup.' + Key + 'Options.' + Item) }}</span>
                  </label>
                </div>
                <template v-else>
                  <div v-for="(SubFeatures, Index) in FeatureData" :key="SubFeatures + Index">
                    <template v-if="SubFeatures && SubFeatures.length > 0">
                      <h6 class="mb-3">{{ $t('Organization/Manager.FeaturesGroup.' + Key + 'Options.' + Index) }}</h6>
                      <div class="form-inline mb-4">
                        <label v-for="Item in SubFeatures" :key="Item" :for="Key + Item + Index" class="mr-2">
                          <input v-if="Permissions.Features[Key]" type="checkbox" :id="Key + Item + Index" :name="Key" :value="Item"
                                 v-model="Permissions.Features[Key][Index]"><span
                          class="ml-2">{{ $t('Organization/Manager.FeaturesGroup.' + Key + 'Options.' + Item) }}</span>
                        </label>
                      </div>
                    </template>
                  </div>
                </template>
              </template>
            </template>
          </div>
        </CTab>
<!--        <CTab :title="$t('Organization/Manager.Permission')">-->
<!--          <pre>{{ CurrentPermission.Permission }}</pre>-->
<!--          {{ Permissions.Permission }}-->
<!--        </CTab>-->
      </CTabs>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true) || (Loading === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#CreateSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="CreateManagerModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton v-if="Action === 'Create'" id="CreateSubmit" @click="AddManager()" color="success">
            {{ $t('Global.Add') }}
          </CButton>
          <CButton v-if="Action === 'Update'" id="CreateSubmit" @click="UpdateManager()" color="success">
            {{ $t('Global.Update') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
  "meta": {
    "label": "管理者列表"
  }
}
</route>

<script>
export default {
  name: 'ListManage',
  layout: 'manage',
  computed: {
    Field() {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'email', label: this.$t('Organization.Account') },
        { key: 'displayName', label: this.$t('Organization.Name') },
        { key: 'identity', label: this.$t('Personal.Profile/Identity') },
        { key: 'disabled', label: this.$t('Organization.Status') },
        { key: 'Action', label: '' }
      ]
    },
    CurrentPermission() {
      return localStorage.getItem('Permission') ? JSON.parse(localStorage.getItem('Permission')) : {
        Navigation: {},
        Permission: {},
        Features: {}
      }
    },
  },
  data () {
    return {
      Action: 'Create',
      ChooseUsers: [],
      CreateManagerModel: false,
      Loading: false,
      Submit: false,
      PasswordVisible: false,
      List: [],
      User: {
        enabled: false
      },
      UserData: {},
      Permissions: {
        Navigation: {},
        Features: {},
        Permission: {},
      },
      noItemsView: {
        noResults: this.$t('Organization/Member.NoResults'),
        noItems: this.$t('Organization/Member.NoItems')
      },
      SetProject: null
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/manager/list',
        method: 'post',
        data: {}
      }).then(({data}) => {
        this.Loading = false
        this.List = data.user
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetManager (uid) {
      this.Action = 'Update'
      this.Loading = true
      this.CreateManagerModel = true
      this.Permissions = {
        Navigation: {},
        Features: {},
        Permission: {},
      }
      this.User = {}
      this.UserData = {}
      return this.$store.dispatch('InnerRequest', {
        url: '/manager/get',
        method: 'post',
        data: {
          CurrentUserID: uid
        }
      }).then(({ data }) => {
        this.Loading = false
        this.User = data.User
        this.User.enabled = !data.User.disabled
        this.UserData = data.UserData
        this.Permissions = data.Permission
        Object.keys(this.CurrentPermission.Navigation).forEach(key => {
          if (!this.Permissions.Navigation[key]) {
            this.$set(this.Permissions.Navigation, key, [])
          }
        })
      }).catch((error) => {
        console.log(error)
        this.Loading = false
        this.CreateManagerModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$i18n.messages[this.$i18n.locale]['Organization/Manager'][error.code] ? this.$i18n.messages[this.$i18n.locale]['Organization/Manager'][error.code] : error.msg,
          type: 'error'
        })
      })
    },
    AddManagerModal () {
      this.Permissions = {
        Navigation: {},
        Features: {},
        Permission: {},
      }
      this.User = {}
      this.UserData = {}
      this.CreateManagerModel = true
      this.Action = 'Create'
      Object.keys(this.CurrentPermission.Navigation).forEach(key => {
        if (!this.Permissions.Navigation[key]) {
          this.$set(this.Permissions.Navigation, key, [])
        }
      })
    },
    AddManager () {
      const User = this.User
      if (this.UserData.Mobile) {
        User.phoneNumber = this.UserData.Mobile
      } else {
        User.phoneNumber = null
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/manager/add',
        method: 'post',
        data: {
          Permission: {
            ...JSON.parse(localStorage.getItem('Permission')),
            Navigation: this.Permissions.Navigation
          },
          User,
          UserData: this.UserData,
          SetProject: this.SetProject
        }
      }).then(() => {
        this.Loading = false
        this.CreateManagerModel = false
        this.GetList()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Organization/AddManagerSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$i18n.messages[this.$i18n.locale]['Organization/Manager'][error.code] ? this.$i18n.messages[this.$i18n.locale]['Organization/Manager'][error.code] : error.msg,
          type: 'error'
        })
      })
    },
    UpdateManager () {
      this.Loading = true
      const User = this.User
      if (this.UserData.Mobile) {
        User.phoneNumber = this.UserData.Mobile
      } else {
        User.phoneNumber = null
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/manager/update',
        method: 'post',
        data: {
          Permission: {
            Navigation: this.Permissions.Navigation,
            Features: this.Permissions.Features,
            Permission: this.Permissions.Permission,
          },
          User: this.User,
          UserData: this.UserData
        }
      }).then(() => {
        this.Loading = false
        this.CreateManagerModel = false
        this.GetList()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Organization/EditManagerSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$i18n.messages[this.$i18n.locale]['Organization/Manager'][error.code] ? this.$i18n.messages[this.$i18n.locale]['Organization/Manager'][error.code] : error.msg,
          type: 'error'
        })
      })
    },
    RemoveManager (uid) {
      return this.$swal({
        icon: 'info',
        title: this.$t('Message.AskDelete'),
        text: '此操作將不可回覆，請您再次確認是否刪除管理員？',
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Confirm'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then((result) => {
          if (result.isConfirmed) {
            this.Loading = true
            return this.$store.dispatch('InnerRequest', {
              url: '/manager/remove',
              method: 'post',
              data: {
                CurrentUserID: uid
              }
            }).then(() => {
              this.Loading = false
              this.GetList()
              this.$notify({
                group: 'notify',
                title: this.$t('Message.Success'),
                text: this.$t('Message.Organization/DeleteManagerSuccess'),
                type: 'success'
              })
            }).catch((error) => {
              this.Loading = false
              this.$notify({
                group: 'notify',
                title: this.$t('Message.Error'),
                text: this.$i18n.messages[this.$i18n.locale]['Organization/Manager'][error.code] ? this.$i18n.messages[this.$i18n.locale]['Organization/Manager'][error.code] : error.msg,
                type: 'error'
              })
            })
          }
      })
    },
    CheckPhone () {
      switch (this.UserData.Country) {
        case 'TW':
        default:
          this.UserData.Mobile = this.UserData.Mobile.replace(/^09/, '+8869')
          break
      }
    },
  }
}
</script>

<style lang="scss">
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#ManagerList {
  thead {
    th {
      white-space: nowrap;
      &:first-child {
        text-align: center;
      }
    }
  }
  tbody {
    td {
      white-space: nowrap;
      &:first-child {
        vertical-align: middle;
      }
    }
  }
}
</style>
